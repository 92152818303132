<template>
    <div>
        <div class="fotter_box_pc shubiao" v-if="screenWidth >= 1024">
            <div class="fotter_box_top">
                <div class="left">
                    <div v-if="item.goods_list.length > 0" class="illumination"
                        v-for="(item, index) in info.goods_cate_one" :key="index">
                        <div class="illumination_title">
                            {{ item.name }}
                        </div>
                        <div @click="toProductDetails(item1)" v-for="(item1, index1) in item.goods_list" :key="index1"
                            class="illumination_list">
                            {{ item1.name }}
                        </div>
                    </div>
                    <div class="illumination">
                        <div class="illumination_title">
                            {{ $t('digitalScheme.digitalScheme7') }}
                        </div>
                        <div @click="toshuzi(0)" class="illumination_list">
                            {{ $t('digitalScheme.digitalScheme2') }}
                        </div>
                        <div @click="toshuzi(1)" class="illumination_list">
                            {{ $t('digitalScheme.digitalScheme3') }}
                        </div>
                        <div @click="toshuzi(2)" class="illumination_list">
                            {{ $t('digitalScheme.digitalScheme4') }}
                        </div>
                        <div @click="toshuzi(3)" class="illumination_list">
                            {{ $t('digitalScheme.digitalScheme5') }}
                        </div>
                        <div @click="toshuzi(4)" class="illumination_list">
                            {{ $t('digitalScheme.digitalScheme6') }}
                        </div>
                    </div>
                    <div class="illumination" v-for="(item, index1) in illuminations" :key="item.ids">
                        <div @click="tous(item.ids)" v-for="(item) in item.children" :key="item.ids"
                            :class="{ 'illumination_list': item.title1, 'illumination_title': item.title, 'illumination_title2': item.title2 }">
                            {{ item.title ? item.title : item.title1 }}
                            {{ item.title2 }}
                        </div>
                        <div @click="toFriendlylink(item)" v-if="index1 == 0" class="illumination_list"
                            v-for="(item, index) in info.friend_link" :key="index">
                            {{ item.name }}
                        </div>
                    </div>
                    <div class="illumination">
                        <div class="illumination_title">
                            {{ $t('TrailingPage.TrailingPage1') }}
                        </div>
                        <div class="illumination_title2">
                            {{ info.tel }}
                        </div>
                        <div class="illumination_title">
                            {{ $t('TrailingPage.TrailingPage2') }}
                        </div>
                        <div style="margin-bottom: 15px;display: flex;flex-wrap: wrap;" class="illumination_title2"
                            v-for="(item, index) in info.zongaddress" :key="index">
                            <div style="margin-right: 5px;margin-bottom: 5px;">{{ item.name }} :</div>
                            <div>{{ item.value }}</div>
                        </div>

                    </div>
                </div>
                <div class="right">
                    <div class="logo_erweima">
                        <img class="logo" src="../../src/assets/image/logo1.png" alt="">
                        <div class="erweima">
                            <div class="erweima_img">
                                <img :src="info.douyin" alt="">
                                <div>{{ $t('TrailingPage.TrailingPage6') }}</div>
                            </div>
                            <div class="erweima_img">
                                <img :src="info.gongzhong" alt="">
                                <div>{{ $t('TrailingPage.TrailingPage5') }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tail">
                <div class="copyright">Copyright © 2022 All Rights Reserved　{{ $t('TrailingPage.TrailingPage4') }} <a
                        class="beian" href="https://beian.miit.gov.cn/#/Integrated/index">{{
                            $t('TrailingPage.TrailingPage7') }}</a></div>
                <div class="copyright_bottom">
                    <div class="bai"></div>
                    <div class="xian"></div>
                </div>
            </div>
        </div>
        <div class="fotter_box_Move" v-if="screenWidth < 1024">
            <div class="fotter_box_top">
                <div class="fotter_box_top_left">
                    <img class="logo" src="../../src/assets/image/logo1.png" alt="">
                    <div class="lianxi_box">
                        <div class="lianxi_title">{{ $t('TrailingPage.TrailingPage1') }}</div>
                        <div class="lianxi_hao">{{ info.tel }} </div>
                    </div>
                    <div class="lianxi_box">
                        <div class="lianxi_title">{{ $t('TrailingPage.TrailingPage2') }}</div>
                        <div class="dizhi_move" v-for="(item, index) in info.zongaddress" :key="index">
                            <div style="margin-right: 5px;margin-bottom: 5px;">{{ item.name }} :</div>
                            <div>{{ item.value }}</div>
                        </div>
                    </div>

                </div>
                <div class="fotter_box_top_right">
                    <div class="tipFive">
                        <img class="QRcode" :src="info.douyin" alt="">
                        <div>{{ $t('TrailingPage.TrailingPage6') }}</div>
                    </div>
                    <div class="tipFive">
                        <img class="QRcode" :src="info.gongzhong" alt="">
                        <div>{{ $t('TrailingPage.TrailingPage5') }}</div>
                    </div>
                </div>
            </div>
            <div class="fotter_box_bottom">
                <div class="copyright">Copyright © 2022 All Rights Reserved　{{ $t('TrailingPage.TrailingPage4') }} <a
                        class="beian" href="https://beian.miit.gov.cn/#/Integrated/index">{{
                            $t('TrailingPage.TrailingPage7') }}</a></div>
                <div class="copyright_bottom">
                    <div class="bai"></div>
                    <div class="xian"></div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            info: '',
            screenWidth: window.innerWidth, // 初始化屏幕宽度
            illuminations: [
                {
                    children: [
                        {
                            title: '关于我们',
                            ids: -1
                        },
                        {
                            title1: '企业简介',
                            ids: 0
                        },
                        {
                            title1: '科研实力',
                            ids: 1
                        },
                        {
                            title1: '荣誉资质',
                            ids: 2
                        },
                        {
                            title1: '企业文化',
                            ids: 3
                        },
                        {
                            title: '友情链接',
                            ids: 4
                        },
                    ]
                },
            ],
        }
    },
    created() {
        // 监听窗口大小变化事件
        window.addEventListener("resize", this.updateScreenWidth);
    },
    mounted() {
        this.getfooter(0)

    },
    methods: {
        toshuzi(index) {
            this.$router.push({ path: '/digitalScheme', query: { index: index } })
        },
        tolx() {
            console.log('123');
            this.$router.push({ path: '/contactUs' })
        },
        //友情链接
        toFriendlylink(item) {
            window.open(item.url, '_blank');
        },
        //去关于我们
        tous(index) {
            this.$router.push({
                path: '/About_us',
                query: { index: index }
            })
        },
        /**
        * 去产品详情
        * 刘义博
        * 2024-5-9
        */
        toProductDetails(item1) {
            this.$router.push({
                path: '/productDetails',
                query: {
                    id: item1.id
                },
            })
        },
        /**
         * 底部数据
         * 刘义博
         * 2024-5-10
        */
        getfooter(is_e) {
            this.$request({
                url: '/Index/bottom',
                data: {
                    is_e: is_e,
                }
            }).then(res => {
                console.log('公共底部数据', res);
                this.info = res
                this.illuminations[1].children[1].title = res.tel
                this.illuminations[1].children[3].title = res.zongaddress

            })
        },
        /**
         * 更新屏幕宽度
         * 刘义博
         * 2024-5-7
        */
        updateScreenWidth() {
            this.screenWidth = window.innerWidth; // 更新屏幕宽度
        },
    },
    watch: {
        '$i18n.locale': function (newLocale, oldLocale) {
            this.getfooter(localStorage.getItem('Language'))
            if (newLocale == 'en') {
                this.illuminations = [
                    {
                        children: [
                            {
                                title: 'About us',
                                ids: -1
                            },
                            {
                                title1: 'Company profile',
                                ids: 0
                            },
                            {
                                title1: 'Scientific research strength',
                                ids: 1
                            },
                            {
                                title1: 'Honor and qualification',
                                ids: 2
                            },
                            {
                                title1: 'Corporate culture',
                                ids: 3
                            },
                            {
                                title: 'Friendly link',
                                ids: 4
                            },
                        ]
                    },
                ]

            } else if (newLocale == 'zh') {
                this.illuminations = [
                    {
                        children: [
                            {
                                title: '关于我们',
                                ids: -1
                            },
                            {
                                title1: '企业简介',
                                ids: 0
                            },
                            {
                                title1: '科研实力',
                                ids: 1
                            },
                            {
                                title1: '荣誉资质',
                                ids: 2
                            },
                            {
                                title1: '企业文化',
                                ids: 3
                            },
                            {
                                title: '友情链接',
                                ids: 4
                            },
                        ]
                    },
                ]
            }
        }
    },
    computed: {},
    filters: {},
    components: {},
    beforeDestroy() {
        // 移除事件监听器以避免内存泄漏
        window.removeEventListener("resize", this.updateScreenWidth);

    },
}
</script>
<style scoped lang="less">
.beian {
    color: #92A1CD;
}

.beian:hover {
    color: #ffffff;
}

.fotter_box_Move {
    padding: 100px 160px 100px 160px;
    background: #004483;

    .fotter_box_top {
        display: flex;
        // align-items: center;
        justify-content: space-between;
    }

    .fotter_box_bottom {
        margin-top: 150px;
        cursor: default;

        .copyright {
            width: 87%;
            font-weight: 400;
            font-size: 50px;
            color: #92A1CD;
            line-height: 100px;
        }

        .copyright_bottom {
            display: flex;
            align-items: center;
            margin-top: 20px;

            .bai {
                width: 50px;
                height: 10px;
                background: #F0F4FF;
            }

            .xian {
                width: 100%;
                height: 2px;
                background: #3B57A9;
            }
        }
    }

    .fotter_box_top_left {
        .logo {
            width: 390px;
            height: 180px;
            margin-bottom: 20px;
        }

        .lianxi_box {
            margin-top: 150px;
            margin-bottom: 80px;

            .dizhi_move {
                display: flex;
                flex-wrap: wrap;
                margin-top: 50px;

                div {
                    font-size: 50px;
                    color: #92A1CD;
                    width: 1000px;
                }
            }

            .lianxi_title {
                font-size: 65px;
                color: #fff;
            }

            .lianxi_hao {
                font-size: 50px;
                color: #92A1CD;
                margin-top: 50px;
                width: 900px;
            }
        }
    }

    .fotter_box_top_right {
        .tipFive {
            display: flex;
            align-items: center;
            flex-direction: column;
            margin-bottom: 42px;

            div {
                font-size: 44px;
                font-family: FZFengYaSongS-GB;
                font-weight: bold;
                color: #fff;
                text-align: center;
            }
        }

        .tipFive:last-child {
            margin-bottom: 0 !important;
        }

        .QRcode {
            width: 300px;
            height: 300px;
            background: #ffffff;
            margin-bottom: 55px;
        }
    }
}

.fotter_box_pc {
    padding: 70px 260px 59px 260px;
    background: #004483;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    // background-position: center top;
    .fotter_box_top {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }


    .tail {
        width: 100%;
        margin-top: 90px;
        cursor: default;

        .copyright {
            font-weight: 400;
            font-size: 14px;
            color: #92A1CD;
        }

        .copyright_bottom {
            display: flex;
            align-items: center;
            margin-top: 20px;

            .bai {
                width: 22px;
                height: 5px;
                background: #F0F4FF;
            }

            .xian {
                width: 100%;
                height: 1px;
                background: #3B57A9;
            }
        }
    }

    .left {
        display: flex;

        .illumination {
            max-width: 230px;
            margin-right: 70px;

            .illumination_title {

                font-weight: bold;
                font-size: 24px;
                color: #F0F4FF;
                margin: 25px 0;
                // white-space: nowrap;
            }

            .illumination_title2 {
                font-weight: bold;
                font-size: 14px;
                color: #D1DDFF;
                margin-bottom: 39px;
            }

            .illumination_list {
                font-weight: 400;
                font-size: 14px;
                color: #92A1CD;
                margin-bottom: 15px;
                cursor: pointer !important;
            }

            .illumination_list:hover {
                color: #fff;
            }
        }
    }

    .right {
        margin-top: 30px;

        .logo_erweima {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .logo {
            width: 118px;
            height: 52px;
            margin-bottom: 44px;
        }

        .erweima .erweima_img:nth-child(2) {
            margin-right: 0px !important;
        }

        .erweima {
            display: flex;
            // align-items: center;

            .erweima_img {
                margin-right: 35px;
                text-align: center;

                img {
                    width: 91px;
                    height: 91px;
                }

                div {
                    width: 91px;
                    font-weight: bold;
                    font-size: 14px;
                    color: #F0F4FF;
                    margin-top: 10px;
                }
            }
        }
    }
}
</style>