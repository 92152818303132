<template>
    <div>
        <div class="head_pc" v-if="screenWidth >= 1024">
            <div :class="{'head_box':true,'head_box_active':Linguisticjudgment == 2}">
                <div @click="toindexs"
                    :class="{ 'logo_boxactive': Linguisticjudgment == 2, 'logo_box': Linguisticjudgment == 1 }">
                    <img class="logo animate_left" src="../assets/image/logo.png" alt="">
                </div>

                <div @mouseleave="tabhuachu" class="Tablist">
                    <div @mouseover="tabhover(index)" @click="tabClick(index)"
                        :class="{ 'Options_tabactive': Linguisticjudgment == 2, 'Options_tab': Linguisticjudgment == 1 }"
                        v-for="(item, index) in NavList" :key="index">
                        <div>
                            {{ Linguisticjudgment == 1 ? item.title : item.titleEN }}
                        </div>
                        <div v-if="NavListIndex == index" class="buloe"></div>
                    </div>
                </div>
                <div class="Language" @click="languageChange">
                    <img src="../assets/image/dq.png" alt="">
                    <div>{{ $t("publicHead.language") }}</div>
                </div>
            </div>
            <!-- 工业照明弹框 -->
            <div @mouseleave="illumination" class="Industrial_lighting"
            v-if="NavListIndex == 1 && show == false">
                <div v-if="item.children[0].name != ''" class="Industrial_box" v-for="(item, index) in info" :key="index">
                    <div class="Industrial_title">
                        {{ item.name }}
                    </div>
                    <div class="Industrial_list">
                        <div @click="tozhaoming(item1)" v-for="(item1, index1) in item.children"
                            class="Industrial_list_box" :key="index1">
                            {{ item1.name }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="head_move" v-if="screenWidth < 1024">
            <div class="head_box">
                <div class="cd">
                    <img @click="drawer = true" src="../assets/image/cd.png" alt="">
                </div>
                <img @click="toindexs" class="logo" src="../assets/image/logo.png" alt="">
                <div class="Language" @click="languageChange">
                    <img src="../assets/image/dq.png" alt="">
                    <div>{{ $t("publicHead.language") }}</div>
                </div>
            </div>
            <el-drawer  title="我是标题" direction="ltr" :visible.sync="drawer" :with-header="false" size="80%">
                <div class="menu_box">
                    <img class="logo" src="../assets/image/logo.png" alt="">
                    <!-- tab列表 -->
                    <div class="tabbar_list">
                        <div @click="menuclick(index)" :class="{'tabbar_box':true,'tabbar_boxActive':menu_listindex == index}" v-for="(item,index) in menu_list" :key="index">
                            <div class="tab_zh">{{ item.titleZh }}</div>
                            <div class="tab_en">{{ item.titleEn }}</div>
                        </div>
                    </div>
                </div>
            </el-drawer>
        </div>
    </div>
</template>
<script>
import { Toast} from 'vant'
export default {
    data() {
        return {
            info: '',//产品数据
            show: false,
            NavListIndex: 0,
            NavListIndexs: 0,
            Linguisticjudgment: 1,  //1=中文  2=英文
            NavList: [
                {
                    title: '首页',
                    titleEN: 'Home page',
                },
                {
                    title: '工业照明',
                    titleEN: 'Industrial lighting',
                },
                {
                    title: '数字化解决方案',
                    titleEN: 'Digital solution',
                },
                {
                    title: '行业方案',
                    titleEN: 'Industry program',
                },
                {
                    title: '成功案例',
                    titleEN: 'Successful case',
                },
                {
                    title: '新闻资讯',
                    titleEN: 'News information',
                },
                {
                    title: '关于我们',
                    titleEN: 'About us',
                },
                {
                    title: '联系我们',
                    titleEN: 'Contact us',
                },
            ],
            currentScroll: 0,
            screenWidth: window.innerWidth, // 初始化屏幕宽度
            drawer: false,//菜单弹框
            menu_listindex:0,//当前选中的菜单
            menu_list:[
                {
                    titleZh:'首页',
                    titleEn:'Home page'
                },
                {
                    titleZh:'工业照明',
                    titleEn:'Industrial lighting'
                },
                {
                    titleZh:'数字化解决方案',
                    titleEn:'Digital solution'
                },
                {
                    titleZh:'行业方案',
                    titleEn:'Industry program'
                },
                {
                    titleZh:'成功案例',
                    titleEn:'Successful case'
                },
                {
                    titleZh:'新闻资讯',
                    titleEn:'News information'
                },
                {
                    titleZh:'关于我们',
                    titleEn:'About us'
                },
                {
                    titleZh:'联系我们',
                    titleEn:'Contact us'
                },
            ]
        }
    },
    created() {
		// 监听窗口大小变化事件
		window.addEventListener("resize", this.updateScreenWidth);
	},
    beforeDestroy() {
		// 移除事件监听器以避免内存泄漏
		window.removeEventListener("resize", this.updateScreenWidth);
	},
    mounted() {
        let that = this
        this.getlist(0)
        that.$router.afterEach((to, from) => {
            if (that.$router.currentRoute.meta.index == '-1') {
                this.show = false
                return
            }
            that.NavListIndex = that.$router.currentRoute.meta.index
            that.NavListIndexs = that.$router.currentRoute.meta.index
            that.menu_listindex = that.$router.currentRoute.meta.index
            if (that.$router.currentRoute.meta.index == 1) {
                this.show = true
            } else {
                this.show = false
            }
            // console.log(that.$router.currentRoute.meta.index);
        });
        window.addEventListener("scroll", this.handleScrollbox, true);


    },
    watch: {
        '$i18n.locale': function (newLocale, oldLocale) {
            this.getlist(localStorage.getItem('Language'))
            if (newLocale == 'en') {
                this.Linguisticjudgment = 2
            } else if (newLocale == 'zh') {
                this.Linguisticjudgment = 1
            }
        }
    },
    methods: {
        menuclick(index){
            if (this.menu_listindex == index) return
            this.menu_listindex = index

            if (index == 0) {
                this.$router.push('/')
            }else if(index == 1){
                this.$router.push('/illumination')
            } else if (index == 2) {
                this.$router.push('/digitalScheme')
            } else if (index == 4) {
                this.$router.push('/successfulCase')
            } else if (index == 3) {
                this.$router.push('/enterpriseProgram')
            } else if (index == 5) {
                this.$router.push('/newsConsultation')
            } else if (index == 6) {
                this.$router.push('/About_us')
            } else if (index == 7) {
                this.$router.push('/contactUs')
            }
            this.drawer = false
        },
        handleScrollbox() {
            this.currentScroll = window.pageYOffset; //表示当前滚动的位置
            this.screenWidth = window.innerWidth
            // if (this.screenWidth >= 1024) {
            //     if (this.currentScroll <= 10) {
            //         this.$(".head_box").removeClass("fixed");
            //     } else {
            //         this.$(".head_box").addClass("fixed");
            //     }
            // }
        },
        /**
         * 获取产品列表
         * 刘义博
         * 2024-5-10
         */
        getlist(is_e) {
            this.$request({
                url: '/Index/goodsCateAll',
                data: {
                    is_e: is_e,
                }
            }).then(res => {
                this.info = res.cate_list_tree
            })
        },
        //去产品列表
        tozhaoming(item) {
            this.$router.push({
                path: '/illumination',
                query: { item: JSON.stringify(item) }
            })
        },
        /**
		 * 更新屏幕宽度
		 * 刘义博
		 * 2024-5-7
		 */
		updateScreenWidth() {
			this.screenWidth = window.innerWidth; // 更新屏幕宽度
		},
        //划出首页弹框
        illumination() {
            this.NavListIndex = this.NavListIndexs
        },
        //划出tab
        tabhuachu() {
            if (this.NavListIndex == 1) {//判断是工业照明就阻止
                return
            }
            this.NavListIndex = this.NavListIndexs
        },
        //划入tab
        tabhover(index) {
            this.NavListIndex = index
        },
        //点击tab
        tabClick(index) {
            if (this.NavListIndex == 1) return
            // if (index == 2) {
            //     console.log('123123');
            //     this.$message({
            //         message: '该模块正在开发中~',
            //         offset: 100,
            //     });
            //     return
            // }
            if (this.NavListIndex == this.NavListIndexs) return
            this.NavListIndex = index
            this.NavListIndexs = index
            if (index == 0) {
                this.$router.push('/')
            } else if (index == 2) {
                this.$router.push('/digitalScheme')
            } else if (index == 4) {
                this.$router.push('/successfulCase')
            } else if (index == 3) {
                this.$router.push('/enterpriseProgram')
            } else if (index == 5) {
                this.$router.push('/newsConsultation')
            } else if (index == 6) {
                this.$router.push('/About_us')
            } else if (index == 7) {
                this.$router.push('/contactUs')
            }
        },
        languageChange() {
            console.log(this.$i18n.locale, "语言切换")
            if (this.$i18n.locale == "zh") {
                this.$i18n.locale = "en";
            } else {
                this.$i18n.locale = "zh";
            }
        },
        toindexs() {
            this.$router.push('/')
        },
    },
    computed: {},
    filters: {},
    components: {}
}
</script>
<style scoped lang="less">
//移动显示
@media (max-width: 1023px) {
    ::v-deep .el-drawer{
        z-index: 100 !important;
    }
    .menu_box {
        width: 100%;
        padding: 200px 150px 150px 150px;
        .logo{
            width: 600px;
            height: 250px;
            display: block;
            margin: 0 auto;
        }
        .tabbar_list{
            margin-top: 200px;
            .tabbar_box{
                width: 100%;
                padding: 100px 0; 
                border-bottom: 7px solid #eeeeee;
                display: flex;
                align-items: center;
                .tab_zh{
                    font-size: 80px;
                    font-weight:bold;
                    color: #222222;
                    margin-right: 100px;
                }
                .tab_en{
                    font-size: 70px;
                    font-weight:400;
                    color: #AAAAAA;
                }
            }
            .tabbar_boxActive{
                div{
                    color: #0D65B6 !important;
                }
            }
        }
    }

    .head_box {
        width: 100%;
        height: 300px;
        background-color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 9;
        padding: 0 100px;

        transition: height 0.3s;
        -ms-transition: height 0.3s;
        -moz-transition: height 0.3s;
        -webkit-transition: height 0.3s;
        -o-transition: height 0.3s;

        .cd {
            width: 250px;
            height: 250px;
            display: flex;
            align-items: center;
            img{
                width: 120px;
                height: 120px;
            }
        }

        .logo {
            width: 300px;
            height: 150px;
            display: block;
        }

        .Language {
            width: 270px;
            height: 130px;
            background: #0D65B6;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 80px;

            img {
                width: 70px;
                height: 70px;
                margin-right: 20px;
            }

            div {
                font-weight: 400;
                font-size: 60px;
                color: #FFFFFF;
            }
        }

    }
}

//pc 显示
@media (min-width: 1024px) {
    .responsive-container {
        background-color: lightcoral;
    }

    .Industrial_lighting .Industrial_box:last-of-type {
        padding-right: 0px !important;
        border-right: 0;
        margin-right: 0 !important;
    }

    .Industrial_lighting {
        position: fixed;
        top: 100px;
        left: 0;
        width: 100%;
        padding: 46px 260px;
        display: flex;
        // align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        background: #FFFFFF;
        border-top: 6px solid #0D65B6;
        z-index: 3;
        transition: all .9s !important;
        z-index: 99;


        .Industrial_box {
            margin-right: 28px;
            padding-right: 28px;
            border-right: 4px solid #F2F2F2;
            display: flex;
            flex-direction: column;
            // justify-content: space-between;

            .Industrial_title {
                width: 222px;
                height: 46px;
                background: #0D65B6;
                text-align: center;
                line-height: 46px;
                margin-bottom: 12px;
                font-weight: bold;
                font-size: 20px;
                color: #FFFFFF;
               
            }

            .Industrial_list {
                display: grid;
                grid-template-columns: repeat(2, 0fr);
                grid-gap: 12px;
                cursor: pointer !important;


                .Industrial_list_box {
                    width: 105px;
                    min-height: 79px;
                    background: #E6E6E6;
                    font-weight: 600;
                    font-size: 14px;
                    color: #222222;
                    padding: 25px 15px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                }

                .Industrial_list_box:hover {
                    color: #0D65B6;
                }
            }
        }
    }
    .head_box_active{
        padding:0 200px !important;
    }

    .head_box {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100px;
        box-sizing: border-box;
        background-color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: space-between;
        z-index: 9999;

        transition: height 0.3s;
        -ms-transition: height 0.3s;
        -moz-transition: height 0.3s;
        -webkit-transition: height 0.3s;
        -o-transition: height 0.3s;
        padding:0 260px;


        .logo_box {
            margin-right:165px;
        }

        .logo_boxactive {
            margin-right:100px;
        }

        .logo {
            width: 142px;
            height: 63px;
            margin: 18px auto;
            display: block;
            cursor: pointer !important;
        }


        .Tablist {
            display: flex;
            align-items: center;
            height: 100%;

            .Options_tabactive {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 100%;
                position: relative;
                margin-right: 30px;
                font-weight: 400;
                font-size: 18px;
                cursor: pointer !important;
                div{
                    white-space: nowrap;
                }
            }

            .Options_tab {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 100%;
                position: relative;
                margin-right: 40px;
                font-weight: 400;
                font-size: 18px;
                cursor: pointer !important;
                white-space: nowrap;
            }

            .buloe {
                position: absolute;
                left: 50%;
                bottom: 2px;
                transform: translateX(-50%);
                width: 60px;
                height: 6px;
                background: #0D65B6;

                animation: fadeIn;
                animation-duration: 0.5s;
            }
        }

        .Language {
            min-width: 74px;
            min-height: 32px;
            background: #0D65B6;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 16px;
            cursor: pointer !important;

            img {
                width: 20px;
                height: 20px;
                margin-right: 5px;
            }

            div {
                font-weight: 400;
                font-size: 14px;
                color: #FFFFFF;
            }
        }
    }
}

// .fixed {
//     height: 100px;
//     transition: height 0.3s;
//     -ms-transition: height 0.3s;
//     -moz-transition: height 0.3s;
//     -webkit-transition: height 0.3s;
//     -o-transition: height 0.3s;

//     .listItem3 {
//         .logo {
//             width: 130px !important;
//             height: 51px !important;
//         }
//     }
// }
</style>