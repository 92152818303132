module.exports = {
    publicHead:{//头部
        language:'中文'
    },
    TrailingPage:{//底部
        TrailingPage1:'保持联系',
        TrailingPage2:'地址',
        TrailingPage3:'北京市大兴区京东贝科技 园11号楼4层',
        TrailingPage4:'版权所有· 莱奥斯 - ',
        TrailingPage5:'企业公众号',
        TrailingPage6:'企业抖音',
        TrailingPage7:'冀ICP备2021009758号-1',
    },
    commonality:{//公共
        commonality1:'上一篇',
        commonality2:'下一篇',
        commonality3:'首页',
        commonality4:'查看',
        commonality5:'发布时间',
        commonality6:'访问量',
    },
    index:{//首页
        index1:'莱奥斯科技·工业照明',
        index2:'专业研发生产销售',
        index3:'矿用煤安 井下安全使用 防爆灯具 证件全更可靠',
        index4:'进口LED光源',
        index5:'无频闪驱动',
        index6:'配光高效节能',
        index7:'特殊散热设计',
        index8:'国标防爆结构',
        index9:'高规格防护防腐',
        index10:'专注研发 精工匠造',
        index11:'产品广泛应用在煤矿、国家电网、电厂、冶金、铁路、 油田、石化等行业',
        index12:'无忧服务',
        index13:'实力厂家',
        index14:'研发生产销售一体化，产能 充足及时交付，从源头保证 产品质量。',
        index15:'证件齐全',
        index16:'不同灯具拥有相对应的矿用 产品安全标志证书、防爆合 格证书、3C强制认证证书等 使用更放心。',
        index17:'免费方案',
        index18:'所有行业、各种类型项目， 根据项目信息，均可免费提供 选型推荐、设计报价、施工方 案等服务。',
        index19:'售后服务',
        index20:'所有产品均提供全方位、 全过程的24小时电话服务、 特殊情况上门服务。',
        index21:'免费获取解决方案',
        index22:'免费提供智能化项目设计方案、选型推荐、方案报价等。',
        index23:'您的称呼',
        index24:'您的联系方式',
        index25:'提交并免费获取',
        index26:'成功案例',
        index27:'关于我们',
        index28:'企业简介',
        index29:'科研实力',
        index30:'荣誉资质',
        index31:'企业文化',
        index32:'人员定位',
        index33:'智慧照明',
        index34:'电子围栏',
        index35:'数字孪生',
        index36:'定制服务',
        index37:'更多相关产品',
        index38:'查看详情',
    },
    productDetails:{//工业照明
        productDetails1:"工业照明",
        productDetails2:"产品详情",
        productDetails3:"规格参数",
        productDetails4:"产品特点",
        productDetails5:"安装方式",
        productDetails7:"相关推荐",
        productDetails8:"型号",
    },
    digitalScheme:{//数字化解决方案
        digitalScheme1:'数字化解决方案',
        digitalScheme2:'人员定位',
        digitalScheme3:'智慧照明',
        digitalScheme4:'电子围栏',
        digitalScheme5:'数字孪生',
        digitalScheme6:'定制服务',
        digitalScheme7:'数字化项目',
    },
    IndustryProgram:{//行业方案
        IndustryProgram1:'行业方案',
    },
    successfulCase:{//成功案例
        successfulCase1:'成功案例',
        successfulCase2:'工程案例',
        successfulCase3:'合作客户',
        successfulCase4:'品质创造价值 · 合作推动发展',
        successfulCase5:'合作伙伴',
        successfulCase6:'供应商',
    },
    newsConsultation:{//新闻咨询
        newsConsultation1:'新闻资讯',
        newsConsultation2:'行业信息、公司动态、产品知识一站获取！',
    },
    aboutUs:{//关于我们
        aboutUs1:'关于我们',
        aboutUs2:'诚信',
        aboutUs3:'品质',
        aboutUs4:'服务',
        aboutUs5:'创新',
        aboutUs6:'质量方针：全员参与，创造优良品质；持续改进，追求顾客满意。',
        aboutUs7:'企业精神：诚信 品质 服务 创新。',
        aboutUs8:'企业愿景：成为专业照明领域优秀服务企业。',
        aboutUs9:'企业使命：为客户提供“简单可靠，节能环保”的绿色照明，营造“健康安全”的工作环境。',
    },
    contactUs:{//联系我们
        contactUs1:'联系我们',
        contactUs2:'服务热线',
        contactUs3:'邮箱',
        contactUs4:'快速联系',
        contactUs5:'立即提交',
        contactUs6:'您的称呼',
        contactUs7:'您的手机号',
    }
}