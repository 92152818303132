import Vue from 'vue'
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)
//合并对象的方法
function concatkeyvalue(map) {//map = [{..},{..}]
    let newmap = {}
    map.forEach(items => {
        for (var key in items) {
            newmap[key] = items[key]
        }
    })
    return newmap
}
//将各个文件的中英文翻译全部合并
let zh = concatkeyvalue([require('./lang_zh.js')])
let en = concatkeyvalue([require('./lang_en.js')])

const i18n = new VueI18n({
    locale: 'zh',  // 语言标识
    messages: {
        'zh': zh,
        'en': en
    }
})
export default i18n