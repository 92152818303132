import { formToJSON } from 'axios'
import Vue from 'vue'
import VueRouter from 'vue-router'
//引入仓库内容
import store from '../store'

Vue.use(VueRouter)
/**
 * 子级路由单独抽出
 * 刘义博
 * 2023-10-10
*/
// export const IndexRoutes = [

// ]
/**
 * 页面路由配置
 * 刘义博
 * 2023-10-10
*/
const routes = [
  {
    path: '/',
    component: () => import('../pages/index'),
    meta: {
      title: '首页',
      index: 0,
    }
  },
  {
    path: '/illumination',
    component: () => import('../view/illumination'),
    meta: {
      title: '工业照明',
      index: 1,
    }
  },
  {
    path: '/productDetails',
    component: () => import('../view/productDetails'),
    meta: {
      title: '工业照明',
      index: 1,
    }
  },
  {
    path: '/digitalScheme',
    component: () => import('../view/digitalScheme'),
    meta: {
      title: '数字化解决方案',
      index: 2,
    }
  },
  {
    path: '/enterpriseProgram',
    component: () => import('../view/enterpriseProgram'),
    meta: {
      title: '行业方案',
      index: 3,
    }
  },
  {
    path: '/successfulCase',
    component: () => import('../view/successfulCase'),
    meta: {
      title: '成功案例',
      index: 4,
    }
  },
  {
    path: '/newsConsultation',
    component: () => import('../view/newsConsultation'),
    meta: {
      title: '新闻咨询',
      index: 5,
    }
  },
  {
    path: '/About_us',
    component: () => import('../view/About_us'),
    meta: {
      title: '关于我们',
      index: 6,
    }
  },
  {
    path: '/detail',
    component: () => import('../view/detail'),
    meta: {
      title: '详情',
      index: -1,
    }
  },
  {
    path: '/contactUs',
    component: () => import('../view/contactUs'),
    meta: {
      title: '联系我们',
      index: 7,
    }
  },
  {
    path: '/richtext',
    component: () => import('../view/richtext'),
    meta: {
      title: '富文本',
      index: -1,
    }
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
   //页面跳转显示在顶部   
   scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

// 解决重复点击相同路由报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
export default router
