module.exports = {
    publicHead:{//头部
        language:'en'
    },
    TrailingPage:{//底部
        TrailingPage1:'Keep in touch',
        TrailingPage2:'address',
        TrailingPage3:'4 / F, Building 11, Jingdong Bei Science Park, Daxing District, Beijing',
        TrailingPage4:'All rights reserved. Leos - ',
        TrailingPage5:'Enterprise public number',
        TrailingPage6:'Enterprise Tiktok',
        TrailingPage7:'Hebei ICP for 2021009758-1',
    },
    commonality:{
        commonality1:'Previous post',
        commonality2:'Next chapter',
        commonality3:'Home page',
        commonality4:'view',
        commonality5:'Release time',
        commonality6:'Page view',
    },
    index:{//首页
        index1:'Leos Technology · Industrial Lighting',
        index2:'Professional R & D production sales',
        index3:'Mine coal safety underground safety use of explosion-proof lighting documents are more reliable',
        index4:'Imported LED light source',
        index5:'No stroboscopic drive',
        index6:'Light distribution is efficient and energy saving',
        index7:'Special cooling design',
        index8:'National standard explosion-proof structure',
        index9:'High standard protection and corrosion protection',
        index10:'Focus on research and development of fine craftsmen',
        index11:'The products are widely used in coal mine, national power grid, power plant, metallurgy, railway, oil field, petrochemical and other industries',
        index12:'Worry-free service',
        index13:'Strength manufacturer',
        index14:'R&d, production and sales integration, sufficient production capacity and timely delivery, from the source to ensure product quality.',
        index15:'Complete documents',
        index16:'Different lamps have the corresponding mining product safety mark certificate, explosion-proof grid certificate, 3C compulsory certification certificate and other use more assured.',
        index17:'Free plan',
        index18:'All industries, all types of projects, according to the project information, can provide free selection recommendation, design quotation, construction plan and other services.',
        index19:'After-sales service',
        index20:'All products provide a full range of 24-hour telephone service throughout the whole process, special circumstances on-site service.',
        index21:'Get the solution for free',
        index22:'Provide free intelligent project design scheme, selection recommendation, scheme quotation, etc.',
        index23:'Your name',
        index24:'Your contact information',
        index25:'Submit and get it for free',
        index26:'Successful case',
        index27:'About us',
        index28:'Company profile',
        index29:'Scientific research strength',
        index30:'Honor and qualification',
        index31:'Corporate culture',
        index32:'Personnel location',
        index33:'Intelligent lighting',
        index34:'Electronic fence',
        index35:'Digital twin',
        index36:'Customized service',
        index37:'More Related products',
        index38:'View details',
    },
    productDetails:{//工业照明
        productDetails1:"Industrial lighting",
        productDetails2:"Product details",
        productDetails3:"Specification parameter",
        productDetails4:"Product characteristics",
        productDetails5:"Installation mode",
        productDetails7:"Relevant recommendation",
        productDetails8:"Model number",
    },
    digitalScheme:{//数字化解决方案
        digitalScheme1:'Digital solution',
        digitalScheme2:'Personnel location',
        digitalScheme3:'Intelligent lighting',
        digitalScheme4:'Electronic fence',
        digitalScheme5:'Digital twin',
        digitalScheme6:'Customized service',
        digitalScheme7:'Digitization project',
    },
    IndustryProgram:{//行业方案
        IndustryProgram1:'Industry program',
    },
    successfulCase:{//成功案例
        successfulCase1:'Successful case',
        successfulCase2:'Engineering case',
        successfulCase3:'Cooperative customer',
        successfulCase4:'Quality creates value and cooperation promotes development',
        successfulCase5:'Business partner',
        successfulCase6:'supplier',
    },
    newsConsultation:{//新闻咨询
        newsConsultation1:'News information',
        newsConsultation2:'Industry information, company dynamics, product knowledge access!',
    },
    aboutUs:{//关于我们
        aboutUs1:'About us',
        aboutUs2:'integrity',
        aboutUs3:'Quality',
        aboutUs4:'service',
        aboutUs5:'innovate',
        aboutUs6:'Quality policy: Full participation to create good quality; Continuous improvement, the pursuit of customer satisfaction.',
        aboutUs7:'Enterprise spirit: integrity, quality, service and innovation.',
        aboutUs8:'Enterprise vision: To become an excellent service enterprise in the field of professional lighting.',
        aboutUs9:'Enterprise mission: to provide customers with "simple and reliable, energy saving and environmental protection" green lighting, to create a "healthy and safe" working environment.',
    },
    contactUs:{//联系我们
        contactUs1:'Contact us',
        contactUs2:'Service hotline',
        contactUs3:'mailbox',
        contactUs4:'Quick contact',
        contactUs5:'Submit now',
        contactUs6:'Your name',
        contactUs7:'Your mobile number',
    }
    
}