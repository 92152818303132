<template>
  <div id="app">
    <!-- 头部 -->
    <Commonhead></Commonhead>
    <router-view v-if="isRouterAlive" :key="$route.fullPath" />
    <pagefooter></pagefooter>
  </div>
</template>
<script>
// replace(/<img/g, "<img style='width:100%;height:auto;'")
export default {
  provide() {
    return {
      reload: this.reload,
      screenWidth: window.innerWidth,
      isShow: false
    };
  },
  beforeDestroy() {
    // 移除事件监听器以避免内存泄漏
    window.removeEventListener("resize", this.updateScreenWidth);
  },
  mounted() {
    localStorage.setItem('Language', 0)
    window.addEventListener("resize", this.updateScreenWidth);
  },
  data() {
    return {
      isRouterAlive: true,
    };
  },

  methods: {
    /**
     * 解决  swiper bug
     * 刘义博
     * 2024-5-7
     */
    updateScreenWidth() {
      this.screenWidth = window.innerWidth; // 更新屏幕宽度
      if (this.screenWidth >= 1024 && localStorage.getItem('widthshow') != 1) {
        localStorage.setItem('widthshow', 1)
        this.reload()
      } else if (this.screenWidth < 1024) {
        localStorage.setItem('widthshow', 2)
        this.isShow = false
      }
    },
    reload() {
      // console.log("刷新当前页面");
      this.isRouterAlive = false;
      this.$nextTick(function () {
        this.isRouterAlive = true;
        window.scrollTo({
          top: 0,
          behavior: 'smooth' // 平滑滚动效果
        });
      });
    },
  },
  watch: {
    '$i18n.locale': function (newLocale, oldLocale) {
      if (newLocale == 'en') {
        this.Linguisticjudgment = 1
        localStorage.setItem('Language', 1)
        this.reload()
      } else if (newLocale == 'zh') {
        this.Linguisticjudgment = 2
        localStorage.setItem('Language', 0)
        this.reload()
      }
    }
  },
};
</script>
<style lang="less">
@import "/src/assets/css/common.css";
</style>
