import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import axios from 'axios'
Vue.prototype.$axios = axios
import qs from 'qs'
Vue.prototype.$qs = qs

/**
 * 注册全局公共样式
 * 刘义博
 * 2023-10-10
*/
import quanju from './common/index.js'
for(let i in quanju){
  Vue.component(i,quanju[i])
}

import 'amfe-flexible'//rem方法


import { Swipe, SwipeItem} from 'vant'; //引入vant轮播图
import element from 'element-ui';//引入element组件
import 'vant/lib/index.css';
import 'element-ui/lib/theme-chalk/index.css';
import 'animate.css';//自动动画库
import './assets/fontFamily/font.css'

//预览图片
import preview from 'vue-photo-preview'
import 'vue-photo-preview/dist/skin.css'
Vue.use(preview)

Vue.use(Swipe);
Vue.use(element);
Vue.use(SwipeItem);


//中英切换
import VueI18n from 'vue-i18n' //npm install vue-i18n@8.27.2 -S
import i18n from './assets/js/language/lang.js'  //引入lang.js
Vue.use(VueI18n)//使用

//导入全局请求
import request from '@/utils/request.js';
Vue.prototype.BASE_URL = request.BASE_URL
Vue.prototype.$request = request.request;

// jquery
import $ from 'jquery'
Vue.prototype.$ = $


//自定义滚动条
import vuescroll from 'vuescroll';

// 配置参数
Vue.use(vuescroll, {
  ops: {
    vuescroll: {
      mode: "native",
      detectResize: true,  //内容是否根据页面调整
    },  
    bar: {
      showDelay: 11500,
      onlyShowBarOnScroll: true, //是否只有滚动的时候才显示滚动条
      keepShow: false,
      background: '#ccc',
      opacity: 1,
      hoverStyle: false,
      specifyBorderRadius: false,
      minSize: false,
      size: '6px',
      disable: false,
      overflowX: 'hidden',
    },
    scrollPanel: {
      speed: 800,       //多长时间内完成一次滚动。 数值越小滚动的速度越快。
      easing: 'easeInQuad', //默认动画
    }
  },
  name: 'vueScroll' // 在这里自定义组件名字，默认是vueScroll
});
// 定义全局组件
Vue.component('vueScroll', vuescroll);

Vue.config.productionTip = false
new Vue({
  router,
  store,
  i18n,
  render: function (h) { return h(App) }
}).$mount('#app')
